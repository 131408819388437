<template>
  <div class="base-title">
    <base-icon :icon="icon" size="20"></base-icon>
    <div class="text">{{ text }}</div>
  </div>
</template>
<script>
import BaseIcon from "@/components/BaseIcon";
export default {
  name: "base-title",
  components: {
    BaseIcon,
  },
  props: {
    icon: {
      type: String,
      default: "shebei1",
    },
    text: {
      type: String,
      default: "",
    },
  },
};
</script>
<style lang="scss" scoped>
.base-title {
  height: 24px;
  line-height: 24px;
  font-family: "Source Han Sans CN";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  display: flex;
  align-items: center;
  color: #333333;
  .text {
    margin-left: 12px;
  }
}
</style>
