<template>
  <div class="page" v-loading="loading">
    <gc-header :headerData="headerData"> </gc-header>
    <div class="continer-wrapper">
      <div class="device-list">
        <base-title text="设备列表"></base-title>
        <div class="device-box">
          <device-list
            :single-select="true"
            :deviceList="contrastDeviceList"
            @select-change="handleSelectChange"
          ></device-list>
        </div>
      </div>
      <div class="echarts-list">
        <base-title text="实时监测"></base-title>
        <div class="right-box">
          <charts-box
            ref="charts-box"
            :currentDeviceObj="currentDeviceObj"
            :echartsFlag="echartsFlag"
            :params.sync="params"
            @analysis="handleAnalysis"
          ></charts-box>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseTitle from "../components/BaseTitle.vue";
import DeviceList from "../components/DeviceList/index.vue";
import ChartsBox from "@/components/components/chart/index.vue";
import { queryStatusByUser } from "@/api/alarm.js";
import { isBlank } from "@/utils/validate";
import { apiGetDeviceDetail } from "@/api/account";
export default {
  name: "singleGasConsumptionAnalysis",
  components: {
    BaseTitle,
    DeviceList,
    ChartsBox,
  },
  computed: {
    echartsFlag() {
      return !isBlank(this.tabData) && !!this.contrastDeviceList.length;
    },
  },
  data() {
    return {
      userTypeMap: {},
      tabData: null,
      params: {
        time: null, //时间区间
      },
      contrastDeviceList: [],
      loading: false,
      headerData: {
        pic: "abnormal-record",
        title: "客户/站点名称",
        desc: [],
      },
      currentDevice: "", //当前选中的设备id
      currentDeviceObj: {}, //当前选中的设备信息
    };
  },
  methods: {
    getList(checked) {
      this.loading = true;
      let obj = {};
      for (var key in this.params) {
        let val = this.params[key];
        if (key === "time") {
          // 如果没有时间则默认取最近一天内
          if (isBlank(val)) {
            // 一天内
            this.params.time = [
              this.dayjs(new Date().getTime() - 3600 * 1000 * 24).format(
                "YYYY-MM-DD HH:mm:ss"
              ),
              this.dayjs().format("YYYY-MM-DD HH:mm:ss"),
            ];
          }
          obj["startTime"] = this.dayjs(this.params.time[0]).format(
            "YYYY-MM-DD HH:mm:ss"
          );
          obj["endTime"] = this.dayjs(this.params.time[1]).format(
            "YYYY-MM-DD HH:mm:ss"
          );
        } else {
          !isBlank(val) ? (obj[key] = val) : null;
        }
      }
      obj["attributeKey"] =
        "workCumulativeFlow,standardCumulativeFlow,standardInstantaneousFlow,workInstantaneousFlow,pressure,gasTemperature";
      if (checked) {
        obj["attributeKey"] = "";
        let attributeKeyArr = [];
        checked.forEach((key) => {
          if (key === "cumulativeFlow") {
            attributeKeyArr.push(
              "workCumulativeFlow",
              "standardCumulativeFlow"
            );
          } else if (key === "instantaneousFlow") {
            attributeKeyArr.push(
              "standardInstantaneousFlow",
              "workInstantaneousFlow"
            );
          } else {
            attributeKeyArr.push(key);
          }
        });
        obj["attributeKey"] = attributeKeyArr.join(",");
      }
      if (!obj["attributeKey"].length) {
        this.loading = false;
        return;
      }
      queryStatusByUser({
        ...obj,
        deviceId: this.deviceId,
      })
        .then((res) => {
          this.contrastDeviceList = res || [];
          //判断是否存在时点数据
          let statusArr = [];
          this.contrastDeviceList.forEach((item) => {
            item.checked = false;
            Object.keys(item.status).forEach((key) => {
              statusArr.push(item.status[key]);
            });
          });
          if (this.contrastDeviceList.length) {
            const itemIndex =
              this.contrastDeviceList.findIndex(
                (item) => item.deviceId == this.deviceId
              ) || 0;
            this.contrastDeviceList[itemIndex].checked = true;
            this.currentDeviceObj = this.contrastDeviceList[itemIndex];
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 获取设备详情
    async getDeviceDetail(deviceId) {
      this.loading = true;
      await apiGetDeviceDetail(deviceId)
        .then((res) => {
          this.tabData = res;
          const { userType, userPhone, username } = this.tabData;
          this.headerData.title = username;
          this.headerData.desc = [
            { icon: "icon-shebeibianhao", text: this.userTypeMap[userType] },
            { icon: "icon-shebeibianhao", text: userPhone },
          ];
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleSelectChange(item = null, chooseArr = null) {
      this.currentDeviceObj =
        chooseArr && chooseArr.length ? chooseArr[0] : this.currentDeviceObj;
    },
    handleAnalysis(checked) {
      this.getList(checked);
    },
  },
  async created() {
    const { deviceId } = this.$route.query;
    this.deviceId = deviceId;
    const userTypeOption = this.$store.getters?.dataList?.userType || [];
    if (userTypeOption.length) {
      userTypeOption.forEach((item) => {
        this.userTypeMap[item.defaultValue] = item.name;
      });
    }
    await this.getDeviceDetail(this.deviceId);
    this.getList();
  },
};
</script>
<style lang="scss" scoped>
@import "../../runLayout.scss";
.continer-wrapper {
  height: 100%;
  padding: 20px;
  display: flex;
  overflow: hidden;
  .device-list {
    width: 412px;
    height: 100%;
    flex-shrink: 0;
    border-right: 0.5px dashed #999999;
    .device-box {
      height: calc(100% - 24px);
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
  .echarts-list {
    flex: 1;
    padding-left: 20px;
  }
  .right-box {
    padding: 25px 0 0 21px;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
    &-title {
      font-size: 16px;
      line-height: 24px;
      font-weight: 600;
      padding-bottom: 20px;
    }
    .pressure-tap-search {
      display: flex;
      padding-bottom: 20px;
      .ops-btn {
        padding-left: 20px;
        @include base-button(80px);
      }
    }
    .pressure-tap-echarts-box {
      flex: 1;
      width: 100%;
      overflow: auto;
    }
  }
}
</style>
