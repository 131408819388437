<template>
  <ul class="device-list-box">
    <template v-if="contrastDeviceList.length">
      <li
        :class="[
          'device-list-item',
          item.isAlarmFaulty == '1' ? 'warn' : 'normal',
        ]"
        v-for="item in contrastDeviceList"
        :key="item.deviceId"
      >
        <div class="title">
          <div class="left">
            <div class="icon-box">
              <i :class="['iconfont', iconMap[Number(item.firstCategory)]]"></i>
            </div>
            <span class="text">{{ isBlank(item.deviceNo) }}</span>
            <span :class="['status', statusMap[chooseStatus(item)]]">{{
              chooseStatus(item)
            }}</span>
          </div>
        </div>
        <div class="content">
          <li v-for="(content, index) in contentMap" :key="index">
            <i :class="['iconfont', `icon-${content.icon}`]"></i>
            <span class="content-label">{{ content.label }}</span>
            <span class="content-value">{{
              content.filter
                ? content.filter(item[content.value])
                : isBlank(item[content.value])
            }}</span>
          </li>
        </div>
        <div class="check-box">
          <div
            :class="['check-radio', { checked: item.checked }]"
            @click.self="chooseDevice(item)"
          ></div>
        </div>
      </li>
    </template>
    <gc-empty v-else></gc-empty>
  </ul>
</template>
<script>
import { isBlank } from "@/utils/validate";
import GcEmpty from "@/components/common/GcEmpty";
export default {
  name: "device-list",
  components: {
    GcEmpty,
  },
  props: {
    singleSelect: {
      type: Boolean,
      default: true,
    },
    deviceList: {
      type: Array,
      default: () => [],
    },
    text: {
      type: String,
      default: "",
    },
  },
  watch: {
    deviceList: {
      handler(val) {
        if (val) {
          this.contrastDeviceList = this._.cloneDeep(val);
        }
      },
      immediate: true,
      deep: true,
    },
  },
  data() {
    return {
      iconMap: {
        311: "icon-zonghejiankong",
        401: "icon-jiliangyi",
        409: "icon-yalicaiji",
        909: "icon-baojingqi",
        9999: "icon-zonghejiankong",
      },
      userTypeMap: {},
      contrastDeviceList: null,
      selectValue: null,
      contentMap: [
        {
          icon: "kehubianhao",
          label: "流量计编号：",
          value: "flowNo",
        },
        {
          icon: "kehufenlei",
          label: "客户分类：",
          value: "userType",
          filter: (val) => this.userTypeMap[val],
        },
        {
          icon: "tongjingzhi",
          label: "通径值：",
          value: "nominalDiameter",
        },
        {
          icon: "changshangzhandianmingcheng",
          label: "站点名称：",
          value: "username",
        },
        {
          icon: "shebeidizhi",
          label: "设备地址：",
          value: "addressName",
        },
      ],
      statusMap: {
        告警: "orange",
        故障: "red",
        正常: "green",
        离线: "grey",
      },
    };
  },
  methods: {
    isBlank(val) {
      if (isBlank(val)) {
        return "--";
      }
      return val;
    },
    chooseStatus(item) {
      let statusFlag = "正常";
      const { isAlarm, isAlarmFaulty, isAlarmOffline } = item;
      if (isAlarmFaulty == "0" || !isAlarmFaulty) {
        if (isAlarm == "1") {
          statusFlag = "告警";
        } else {
          if (isAlarmOffline == "1") {
            statusFlag = "离线";
          } else {
            statusFlag = "正常";
          }
        }
      } else {
        statusFlag = "故障";
      }
      return statusFlag;
    },

    chooseDevice(item) {
      if (this.singleSelect) {
        this.contrastDeviceList = this.contrastDeviceList.map((i) => {
          i.checked = false;
          item.checked = true;
          return i;
        });
      } else {
        this.contrastDeviceList = this.contrastDeviceList.map((i) => {
          if (i.deviceId === item.deviceId) {
            i.checked = !item.checked;
          }
          return i;
        });
      }
      this.selectValue = this.contrastDeviceList.filter((item) => {
        return item.checked;
      });
      if (!this.singleSelect) {
        this.selectValue.length == 0 &&
          this.$set(item, "checked", !item.checked);
      }
      this.$emit("select-change", item, this.selectValue);
    },
  },
  created() {
    const userTypeOption = this.$store.getters?.dataList?.userType || [];
    if (userTypeOption.length) {
      userTypeOption.forEach((item) => {
        this.userTypeMap[item.defaultValue] = item.name;
      });
    }
  },
};
</script>
<style lang="scss" scoped>
.device-list-box {
  .device-list-item {
    position: relative;
    width: 392px;
    padding: 9px 9px 14px 9px;
    background: #f3f8ff;
    border-radius: 4px;
    margin-top: 12px;
    .title {
      display: flex;
      justify-content: space-between;
      font-weight: 500;
      font-size: 16px;
      height: 24px;
      line-height: 24px;
      color: #333333;
      .left {
        .icon-box {
          display: inline-block;
          width: 25px;
          height: 25px;
          font-size: 16px;
          text-align: center;
          line-height: 25px;
          background: #d1d6f7;
          color: #4d6bff;
          border-radius: 4px;
          i {
            font-size: 22px;
          }
        }
        .text {
          margin: 0 8px;
        }
        .green {
          background-color: #29c287;
        }
        .red {
          background-color: #e5662e;
        }
        .orange {
          background-color: #ff9419;
        }
        .grey {
          background-color: #878EB2;
        }
      }

      .status {
        display: inline-block;
        height: 20px;
        padding: 0 8px;
        line-height: 20px;
        font-size: 12px;
        color: #ffffff;
        text-align: center;
        background: #29c287;
        border-radius: 15px;
      }
    }
    .content {
      margin: 10px 0 0 34px;
      font-size: 14px;
      line-height: 21px;
      color: #5c6073;
      li {
        display: flex;
        align-items: center;
        position: relative;
        color: #6b7399;
        margin-bottom: 4px;
        padding-left: 22px;
        i {
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
        }
      }
      &-label {
        width: 85px;
        flex-shrink: 0;
      }
    }
    .check-box {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 20px;
      .check-radio {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        border: 1px solid #4d6bff;
      }
      .checked {
        background: url("~@/assets/images/icon/choose-icon.png") no-repeat
          center center;
      }
    }
  }
  .normal {
    background-color: #f3f8ff;
  }
  .warn {
    background-color: #f4f4f4;
  }
}
</style>
